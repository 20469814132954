import { dev } from '$app/environment';
import { PUBLIC_SENTRY_ENVIRONMENT } from '$env/static/public';
import { handleErrorWithSentry, init, feedbackIntegration } from '@sentry/sveltekit';

if (!dev) {
	init({
		dsn: 'https://5683f4470b0cc97bd8f4437c57eb975c@o4506469133385728.ingest.us.sentry.io/4506469133582336',
		tracesSampleRate: 0.1,
		replaysSessionSampleRate: 0,
		replaysOnErrorSampleRate: 1.0,
		environment: PUBLIC_SENTRY_ENVIRONMENT,
		integrations: [
			feedbackIntegration({
				colorScheme: 'system',
				isNameRequired: true,
				isEmailRequired: true,
				autoInject: false,
				showBranding: false,
			}),
		],
		debug: false,
		beforeSend(event) {
			return null;
		},
	});
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
