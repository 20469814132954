import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66')
];

export const server_loads = [2,5,6,4];

export const dictionary = {
		"/(app)": [~7,[2]],
		"/(seo)/LP": [46,[5]],
		"/(app)/about": [8,[2]],
		"/(app)/activity": [9,[2]],
		"/admin": [58,[6]],
		"/admin/analytics": [59,[6]],
		"/admin/banners": [60,[6]],
		"/admin/categories": [61,[6]],
		"/admin/characters": [62,[6]],
		"/admin/chat/export": [63,[6]],
		"/admin/create-multiple": [~64,[6]],
		"/admin/users": [65,[6]],
		"/(seo)/adult-ai-chat": [~47,[5]],
		"/(app)/affiliate": [10,[2]],
		"/(seo)/ai-girlfriend-chatbot": [~48,[5]],
		"/(seo)/ai-sex-chat": [~49,[5]],
		"/(seo)/anime-ai-chat": [~50,[5]],
		"/(seo)/blog/[slug]": [~51,[5]],
		"/(app)/categories": [~11,[2]],
		"/(seo)/character-ai-chat": [~52,[5]],
		"/(app)/chats": [~12,[2]],
		"/(app)/clone": [~13,[2]],
		"/(app)/coin-purchase": [~14,[2]],
		"/(app)/community": [15,[2]],
		"/(app)/create": [~16,[2]],
		"/(seo)/dirty-talk-ai": [~53,[5]],
		"/(app)/documents": [17,[2,3]],
		"/(app)/documents/18-USC-2257-Exemption": [18,[2,3]],
		"/(app)/documents/cookies-policy": [19,[2,3]],
		"/(app)/documents/privacy-policy": [20,[2,3]],
		"/(app)/documents/refund-policy": [21,[2,3]],
		"/(app)/documents/terms-conditions": [22,[2,3]],
		"/(app)/email-verification": [23,[2]],
		"/(seo)/erotic-ai-chat": [~54,[5]],
		"/(app)/faq": [24,[2]],
		"/(app)/freeTrial": [25,[2]],
		"/(app)/generate": [~26,[2]],
		"/(seo)/horny-ai-chat": [~55,[5]],
		"/(app)/login": [~27,[2]],
		"/(app)/membersOnly": [28,[2]],
		"/(app)/my-gallery": [~30,[2]],
		"/(app)/my-gallery/[id]": [31,[2]],
		"/(app)/my": [~29,[2]],
		"/(app)/profiles/[id]": [~33,[2]],
		"/(app)/profile": [~32,[2]],
		"/(marketing)/promo001": [43],
		"/(marketing)/promo002": [44],
		"/(quizes)/quiz": [45,[4]],
		"/(app)/ranking": [~34,[2]],
		"/(app)/reset-password": [~35,[2]],
		"/(app)/reset-password/[token]": [~36,[2]],
		"/(app)/rewards": [~37,[2]],
		"/(seo)/roleplay-ai-chat-bot": [~56,[5]],
		"/(seo)/romantic-ai-chat": [~57,[5]],
		"/(app)/shop": [~38,[2]],
		"/(app)/shop/success": [39,[2]],
		"/(app)/signup": [~40,[2]],
		"/(app)/subscribe": [41,[2]],
		"/(app)/support": [~42,[2]],
		"/widgets/register": [~66]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';